<template>
  <div class="confirm">
    <div class="wrapper">
      <div class="panel">
        <div class="panel-hd">
          <h3>配送订单已完成</h3>
          <p class="tips">由于外卖/易碎品的特殊性，容易倾洒造成接收箱污染并阻塞，请及时取出。</p>
        </div>

        <ul class="attrs">
          <li class="attr">
            <div class="attr-title">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-building" />
              </svg>
              <span class="attr-label">地址</span>
            </div>
            <div class="attr-content">
              {{ (data.Community ? data.Community.Name : '-') + (data.Inbox ? data.Inbox.Address : '-') }}
            </div>
          </li>
          <li class="attr">
            <div class="attr-title">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-no" />
              </svg>
              <span class="attr-label">新盒码</span>
            </div>
            <div class="attr-content">
              {{ (data.Inbox ? data.Inbox.No : null) || '--' }}
            </div>
          </li>
          <li class="attr">
            <div class="attr-title">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-box" />
              </svg>
              <span class="attr-label">快递类型</span>
            </div>
            <div class="attr-content">
              {{ ['--', '快递', '易碎品', '外卖'][~~data.Type] }}
            </div>
          </li>
          <li class="attr">
            <div class="attr-title">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-time" />
              </svg>
              <span class="attr-label">派送时间</span>
            </div>
            <div class="attr-content">
              {{ datetime }}
            </div>
          </li>
          <li class="attr">
            <div class="attr-title">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-date" />
              </svg>
              <span class="attr-label">派送耗时</span>
            </div>
            <div class="attr-content">
              {{ usedTime }}
            </div>
          </li>
        </ul>
      </div>

      <div class="panel">
        <ul class="attrs">
          <li class="attr">
            <div class="attr-title">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-courier" />
              </svg>
              <span class="attr-label">快递员</span>
            </div>
            <div class="attr-content">
              {{ data.Courier ? data.Courier.Name : '--' }}
            </div>
          </li>
          <li class="attr">
            <div class="attr-title">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-phone" />
              </svg>
              <span class="attr-label">联系快递员</span>
            </div>
            <div class="attr-content">
              <a v-if="data.Courier" :href="'tel:' + data.Courier.Phone">
                {{ data.Courier.Phone }}
              </a>
              <a v-else @click.prevent>--</a>
            </div>
          </li>
        </ul>
      </div>

      <div class="tips center">未确认收货前，将暂停接收箱使用，请务必取出物品后再确认</div>

      <div class="submit">
        <button
          v-if="data.Status === 3"
          class="btn"
          :disabled="count !== 0"
          @click="postConfirm"
        >
          {{ count === 0 ? '确认收货' : '确认收货（' + count + 's)' }}
        </button>
        <div v-if="data.Status === 4" class="confirmed" :class="{show: data.Status === 4}">
          <svg class="icon confirmed-icon" aria-hidden="true">
            <use xlink:href="#icon-success" />
          </svg>
          <span class="confirmed-text">已确认收货，接收箱功能已恢复</span>
        </div>
      </div>
    </div>
    <svg aria-hidden="true" style="position:absolute;width:0px;height:0px;overflow:hidden;">
      <symbol id="icon-building" viewBox="0 0 36 36">
        <g transform="translate(-74 -383)">
          <path fill="#bcdd33" d="M87.624,165.478H86.062V149.862A1.563,1.563,0,0,0,84.5,148.3H78.254a1.563,1.563,0,0,0-1.562,1.562v15.616H75.131V152.985a1.563,1.563,0,0,0-1.562-1.562H68.885a1.563,1.563,0,0,0-1.562,1.562v12.493H65.762a1.562,1.562,0,0,0,0,3.123H87.624a1.562,1.562,0,1,0,0-3.123ZM72.008,154.546H73.57v1.562H72.008Zm0,3.123H73.57v1.562H72.008Zm0,3.123H73.57v1.562H72.008Zm-3.123-6.246h1.562v1.562H68.885Zm0,3.123h1.562v1.562H68.885Zm0,3.123h1.562v1.562H68.885Zm0,4.685v-1.562h1.562v1.562Zm3.123,0v-1.562H73.57v1.562Zm10.15-14.054H84.5v2.342H82.158Zm0,3.9H84.5v2.342H82.158Zm0,3.9H84.5v2.342H82.158Zm-3.9-7.808H80.6v2.342H78.254Zm0,3.9H80.6v2.342H78.254Zm0,3.9H80.6v2.342H78.254Zm0,6.246v-2.342H80.6v2.342Zm3.9,0v-2.342H84.5v2.342Z" transform="translate(15.8 242.7)"/>
          <rect fill="none" width="36" height="36" transform="translate(74 383)"/>
        </g>
      </symbol>
      <symbol id="icon-courier" viewBox="0 0 36 36">
        <g transform="translate(-70 -507)">
          <g transform="translate(-210.533 86)">
            <path fill="#e8ff8b" d="M-2530.786,758.139l2.987,3.7s5.389-3.938,12.8,0c-.067-.067,2.949-3.946,2.949-3.946l-9.894-4.071Z" transform="translate(2820.123 -319.182)"/>
            <path fill="#bcdd33" d="M206.008,189.926a13.144,13.144,0,0,1-3.582-4.378,10.61,10.61,0,0,1-.68-1.567c-.062,0-.138.007-.2.007a1.906,1.906,0,0,1-1.575-.934h-.008a3.661,3.661,0,0,1,.008-3.814h-.008c.069-.085.132-.177.194-.255v-3.767h-.008a8.289,8.289,0,0,1,8.269-8.269h4.4a8.29,8.29,0,0,1,8.269,8.269v3.775a1.532,1.532,0,0,1,.186.247,3.489,3.489,0,0,1,.54,1.908,3.449,3.449,0,0,1-.54,1.907,1.925,1.925,0,0,1-1.568.934c-.077,0-.139-.007-.216-.007a12.551,12.551,0,0,1-.664,1.567h-.008a13.31,13.31,0,0,1-3.574,4.378A7.021,7.021,0,0,1,206.008,189.926Zm13.967-10.106-2.448,3.2a.652.652,0,0,1-.888.123,10.744,10.744,0,0,0-12.029-.008.6.6,0,0,1-.386.131h-.008a.656.656,0,0,1-.532-.293l-2.425-3.15h-.1c-.031.046-.077.092-.108.146v0a2.142,2.142,0,0,0-.308,1.182,2.2,2.2,0,0,0,.308,1.181.667.667,0,0,0,.494.355c.032,0,.069-.008.109-.008a.176.176,0,0,0,.108-.077.813.813,0,0,1,.216-.1.665.665,0,0,1,.818.448,10.882,10.882,0,0,0,.788,2.023,12.052,12.052,0,0,0,3.227,3.929,5.694,5.694,0,0,0,7.613,0,12.1,12.1,0,0,0,3.242-3.929h-.015a9.968,9.968,0,0,0,.794-2.023.665.665,0,0,1,.811-.448,1.277,1.277,0,0,1,.232.1.2.2,0,0,0,.092.077c.039,0,.077.008.123.008a.692.692,0,0,0,.494-.355,2.262,2.262,0,0,0,.309-1.181,2.2,2.2,0,0,0-.309-1.182v0c-.039-.054-.069-.085-.108-.131l-.1-.015Zm-4.656-2.378a17.664,17.664,0,0,0-13.109,1.5l2.185,2.818a11.846,11.846,0,0,1,12.446,0l2.185-2.818a18.052,18.052,0,0,0-3.707-1.5Z" transform="translate(88.103 260.05)"/>
          </g>
          <rect fill="none" width="36" height="36" transform="translate(70 507)"/>
        </g>
      </symbol>
      <symbol id="icon-time" viewBox="0 0 36 36">
        <g transform="translate(-70 -601)">
          <path fill="#29dec4" d="M75.806,87.354A11.549,11.549,0,1,1,87.355,75.805,11.549,11.549,0,0,1,75.806,87.354ZM66.478,75.361H65.59a.444.444,0,1,0,0,.888h.888a.444.444,0,0,0,0-.888Zm8.884,10.661a.444.444,0,1,0,.888,0v-.888a.444.444,0,1,0-.888,0Zm.888-20.433a.444.444,0,0,0-.888,0v.888a.444.444,0,0,0,.888,0Zm0,10.595V70.03a.444.444,0,0,0-.888,0v6.027l-6.543,6.53a.449.449,0,0,0,.635.634l6.669-6.657A.447.447,0,0,0,76.25,76.183Zm9.772-.822h-.888a.444.444,0,1,0,0,.888h.888a.444.444,0,0,0,0-.888Z" transform="translate(11.743 542.744)"/>
          <rect fill="none" width="36" height="36" transform="translate(70 601)"/>
        </g>
      </symbol>
      <symbol id="icon-date" viewBox="0 0 36 36">
        <g transform="translate(-70 -601)">
          <path fill="#ef4270" d="M75.806,87.354A11.549,11.549,0,1,1,87.355,75.805,11.549,11.549,0,0,1,75.806,87.354ZM66.478,75.361H65.59a.444.444,0,1,0,0,.888h.888a.444.444,0,0,0,0-.888Zm8.884,10.661a.444.444,0,1,0,.888,0v-.888a.444.444,0,1,0-.888,0Zm.888-20.433a.444.444,0,0,0-.888,0v.888a.444.444,0,0,0,.888,0Zm0,10.595V70.03a.444.444,0,0,0-.888,0v6.027l-6.543,6.53a.449.449,0,0,0,.635.634l6.669-6.657A.447.447,0,0,0,76.25,76.183Zm9.772-.822h-.888a.444.444,0,1,0,0,.888h.888a.444.444,0,0,0,0-.888Z" transform="translate(11.743 542.744)"/>
          <rect fill="none" width="36" height="36" transform="translate(70 601)"/>
        </g>
      </symbol>
      <symbol id="icon-no" viewBox="0 0 36 36">
        <g transform="translate(-70 -301)">
          <g transform="translate(12.061 242.337)">
            <path fill="#fec900" d="M83.758,64.663H67.245a3.3,3.3,0,0,0-3.3,3.3V79.494a3.306,3.306,0,0,0,3.3,3.307h3.441a1.65,1.65,0,0,1,1.425.815l1.963,3.35a1.651,1.651,0,0,0,2.849,0l1.967-3.351a1.65,1.65,0,0,1,1.425-.814h3.445a3.3,3.3,0,0,0,3.3-3.3V67.958A3.3,3.3,0,0,0,83.758,64.663Zm0,16.488C79.143,81.149,84.668,81.15,83.759,81.15Z" transform="translate(-0.001)"/>
            <rect fill="#fff" width="18" height="11" rx="2" transform="translate(66.554 68.211)"/>
            <text fill="#fec900" transform="translate(67.554 76.211)" style="font-size:8px;font-family:LiGothicMed, Apple LiGothic;letter-spacing:0.01em;"><tspan x="0" y="0">NO.1</tspan></text>
          </g>
          <rect fill="none" width="36" height="36" transform="translate(70 301)"/>
        </g>
      </symbol>
      <symbol id="icon-phone" viewBox="0 0 36 36">
        <g transform="translate(-82 -472)">
          <path fill="#515151" d="M149.472,5.249h9.115a2.9,2.9,0,0,1,2.8,2.975V23.947a2.9,2.9,0,0,1-2.8,2.975h-9.115a2.9,2.9,0,0,1-2.8-2.975V8.223A2.9,2.9,0,0,1,149.472,5.249Zm4.558,17.486a1.258,1.258,0,1,0,0,2.515,1.258,1.258,0,0,0,0-2.515ZM149.116,8.285a1.364,1.364,0,0,0-1.318,1.4V20.345a1.364,1.364,0,0,0,1.318,1.4h9.827a1.364,1.364,0,0,0,1.318-1.4V9.684a1.364,1.364,0,0,0-1.318-1.4Z" transform="translate(-53.67 473.751)"/>
          <rect fill="none" width="36" height="36" transform="translate(82 472)"/>
        </g>
      </symbol>
      <symbol id="icon-box" viewBox="0 0 36 36">
        <g transform="translate(-70 -402)">
          <path fill="#2482f7" d="M80.591,74.216h8.948a.425.425,0,0,0,.346-.672l-3.262-4.566a1.7,1.7,0,0,0-1.383-.712h-4.65a.425.425,0,0,0-.425.425v5.1A.425.425,0,0,0,80.591,74.216Zm-11.5,0h8.948a.425.425,0,0,0,.425-.425v-5.1a.425.425,0,0,0-.425-.425h-4.65a1.7,1.7,0,0,0-1.383.712l-3.261,4.566a.425.425,0,0,0,.346.672Zm20.847,1.7H68.692a.425.425,0,0,0-.425.425V88.665a1.7,1.7,0,0,0,1.7,1.7h18.7a1.7,1.7,0,0,0,1.7-1.7V76.341A.425.425,0,0,0,89.94,75.916Zm-18.273,6.8h2.55a.85.85,0,1,1,0,1.7h-2.55a.85.85,0,1,1,0-1.7Zm5.1,5.1h-5.1a.85.85,0,1,1,0-1.7h5.1a.85.85,0,1,1,0,1.7Z" transform="translate(8.733 340.733)"/>
          <rect fill="none" width="36" height="36" transform="translate(70 402)"/>
        </g>
      </symbol>
      <symbol id="icon-success" viewBox="0 0 39.805 39.805">
        <path fill="#61de9b" d="M49.76,26.947a19.9,19.9,0,1,0,19.9,19.9A19.9,19.9,0,0,0,49.76,26.947Zm9.79,15.027L50.191,54.6a2.516,2.516,0,0,1-3.238.77l-6.73-4.647a2.094,2.094,0,0,1-.852-2.948,2.511,2.511,0,0,1,3.245-.772l4.96,3.427q3.939-5.317,7.879-10.631c1.7-2.289,5.807-.135,4.1,2.174Z" transform="translate(-29.858 -26.947)"/>    
      </symbol>
    </svg>
  </div>
</template>

<script>
import request from '@/utils/request';
import { useRoute } from 'vue-router';
import { formatDate } from '@/utils/time';
import { computed, onUnmounted, reactive, ref } from 'vue';

export default {
  setup() {
    const route = useRoute();

    let _tid = 0;
    const fetching = ref(false);
    const count = ref(5);
    const data = reactive({});

    const calc = (start, end) => {
      let time = (end - start) / 1000;

      if (time < 60) {
        return time.toFixed(0) + ' 秒';
      }

      let seconds = time % 60;

      return Math.floor(time / 60) + ' 分' + (seconds > 0 ? seconds.toFixed(0) + ' 秒' : ' 钟');
    };

    const datetime = computed(() => data.CreatedAt ? formatDate(data.CreatedAt) : '--');
    const usedTime = computed(() => data.CreatedAt && data.ArrivedAt ? calc(data.CreatedAt, data.ArrivedAt) : '--');

    const initCountdown = () => {
      _tid = setInterval(() => {
        count.value -= 1;
        if (count.value <= 0) {
          clearInterval(_tid);
        }
      }, 1000);
    };
    const getData = async() => {
      const id = route.params.id; // 3P9hc
      const res = await request.call('Delivery.GetPackageByShortID', {ShortID: id});
      const json = res.data;
      if (json.Error) {
        alert(json.Error.detail);
      } else {
        Object.assign(data, json.Data);
        initCountdown();
      }
    };
    const postConfirm = async() => {
      const res = await request.post('v1/gw/rpc?method=Delivery.ConfirmPackageByShortID', {ShortID: id});
      const json = res.data;
      if (json.Error) {
        alert(json.Error.detail);
      } else {
        data.value = json.Data;
      }
    };

    onUnmounted(() => {
      clearInterval(this._tid);
    });

    document.title = '确认收货';
    getData();

    return {
      fetching,
      count,
      data,
      datetime,
      usedTime,
      postConfirm,
    };
  },
};
</script>

<style lang="less" scope>
.confirm {
  min-height: 100vh;
  background-color: #fafcff;

  a {
    color: #00b9d8;
    text-decoration: none;
  }
}
.icon {
  font-size: 1.3125rem;
  vertical-align: -0.375rem;
}
.center {
  text-align: center;
}
.wrapper {
  padding: 1rem;
}
.show {
  display: block !important;
}
.panel {
  margin-bottom: 1rem;
  padding: 1rem 1.25rem;
  background-color: #fff;
  box-shadow: 0px 1px 0.625rem rgba(0, 0, 0, 0.06);
  border-radius: 1rem;
}
.panel-hd {
  margin-bottom: 0.625rem;
  border-bottom: 1px solid #eee;

  h3 {
    margin: 0;
    padding: 5px 0 0;
    font-size: 1rem;
    text-align: center;
  }
}
.tips {
  color: #ababab;
  font-size: 0.6875rem;
  line-height: 1rem;
}
.attrs {
  margin: 0;
  padding: 0;
  line-height: 2.375rem;
  font-size: 0.875rem;
  list-style-type: none;
}
.attr {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.attr-label {
  margin-left: 6px;
  vertical-align: middle;
}
.submit {
  margin-top: 4.25rem;
  text-align: center;
}
.confirmed {
  display: none;
}
.confirmed-icon {
  font-size: 1.5625rem;
}
.confirmed-text {
  margin-left: 0.375rem;
  font-size: 0.875rem;
  vertical-align: middle;
}
.btn {
  width: 14.25rem;
  height: 2.1875rem;
  color: #fff;
  font-size: 0.875rem;
  background-color: #ffcd00;
  border: 1px solid #ffcd00;
  border-radius: 0.3125rem;
  outline: none;

  &:active {
    opacity: 0.7;
  }
  &:disabled {
    background-color: #e2e2e2;
    border-color: #e2e2e2;
  }
}
</style>
