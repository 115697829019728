export function formatDate(number, format) {
  format = format || 'Y/M/D h:m';

  function pad(num) {
    return num < 10 ? `0${num}` : num.toString();
  }

  var date = new Date(number);
  var mapping = {
    Y: date.getFullYear(),
    M: pad(date.getMonth() + 1),
    D: pad(date.getDate()),
    h: pad(date.getHours()),
    m: pad(date.getMinutes()),
    s: pad(date.getSeconds())
  };

  return format.replace(
    new RegExp(`[${Object.keys(mapping).join('')}]`, 'g'), 
    function(word) {
      return mapping[word];
    }
  );
}
